import Logo from "../assets/Logo.png"
import HeroImage from "../assets/HeroImage.png"
import CtaImage from "../assets/CtaImage.jpg"
import Sample from "../assets/sample.jpg"
import avatar from "../assets/avatar.png"
import AboutUs from "../assets/about-us.jpg"
const images = {
    Logo,
    HeroImage,
    CtaImage,
    Sample,
    avatar,
    AboutUs,
}

export default images;
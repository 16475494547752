import React from "react";
import {
  AiOutlineTwitter,
  AiFillYoutube,
  AiFillInstagram,
} from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";
import { BsTelegram } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { images } from "../constants";

const Footer = () => {
  return (
    <section className="bg-dark-hard">
      <footer className="container mx-auto grid px-5 py-10 gap-y-10 gap-x-5 md:pt-20 md:grid-cols-12 lg:gap-x-10 lg:grid lg:grid-cols-2 ">
        <div className="col-span-5 md:col-span-4 lg:col-span-2">
          <h3 className="text-dark-light font-bold md:text-lg">Links</h3>
          <ul className="text-[#959EAD] text-sm mt-5 space-y-4 md:text-base">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/blog">Article</a>
            </li>
            <li>
              <a href="/about-us">About Us</a>
            </li>
            <li>
              <a href="/contact-us">Contact Us</a>
            </li>
           
          </ul>
        </div>

      
        <div className="col-span-10 md:order-first md:col-span-4 lg:col-span-2">
          <img
            src={images.Logo}
            alt="logo"
            className="brightness-0 invert mx-auto md:mx-0 lg:w-[500px]"
          />
          <p className="text-sm text-dark-light text-center mt-4 md:text-left md:text-base lg:text-sm">
          Academic Research Center for Consulting an Development
          </p>
          <ul className="flex justify-center items-center mt-5 space-x-4 text-gray-300 md:justify-start">
           
          
 <li>
              <a href="https://www.linkedin.com/in/academic-research-center-for-consulting-and-development-arccd-454524302/">
                <FaLinkedin className="w-6 h-auto" />
              </a>
            </li>
           
           
            <li>
              <a href="https://www.facebook.com/profile.php?id=61560228472046" target="_blank">
                <FaFacebook className="w-6 h-auto" />
              </a>
            </li>

            <li>
              <a href="https://twitter.com/ARCCDcenter/" target="_blank">
                <BsTwitterX  className="w-6 h-auto" />
              </a>
            </li>
          </ul>
        </div>
        <div className="hidden md:flex flex-col items-center space-y-4 md:col-span-12 lg:col-span-10">
         
          <p className="font-bold italic text-dark-light">
          Copyright © 2024. Mr.Saleh.Ms
          </p>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
